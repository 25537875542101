@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --appColor-black: #000000;
  --appColor-dark: #17151f;
  --appColor-darkAccent: #b84b61;
  --appColor-main: #0a4c92;
  --appColor-lightAccent: #d7b384;
  --appColor-light: #f5f5f6;
  --appColor-light2: #f1f1f1;
  --appColor-error: #ff7961;
  --appColor-errorText: #ba000d;
  --appColor-success: #b3d469;
  --appColor-successText: #517403;
  --appColor-gradient: linear-gradient(90deg, #0a4c92 0%, #68b1fe 100%);
}

* {
  font-family: "Nunito", sans-serif;
}

body {
  background-color: var(--appColor-light);
}

/* Background colors */
.appBgColorBlack {
  background-color: var(--appColor-black);
}
.appBgColorDark {
  background-color: var(--appColor-dark);
}
.appBgColorDarkAccent {
  background-color: var(--appColor-darkAccent);
}
.appBgColorMain {
  background-color: var(--appColor-main);
}
.appBgColorLightAccent {
  background-color: var(--appColor-lightAccent);
}
.appBgColorLight {
  background-color: var(--appColor-light);
}
.appBgColorError {
  background-color: var(--appColor-error);
}
.appBgColorSuccess {
  background-color: var(--appColor-success);
}
.appBgGradient {
  background: var(--appColor-gradient);
}

.specialBannerBg {
  background-image: url("./assets/xmas_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Text colors */
.textColorBlack {
  color: var(--appColor-black);
}
.textColorDark {
  color: var(--appColor-dark);
}
.textColorDarkAccent {
  color: var(--appColor-darkAccent);
}
.textColorMain {
  color: var(--appColor-main);
}
.textColorLightAccent {
  color: var(--appColor-lightAccent);
}
.textColorLight {
  color: var(--appColor-light);
}
.textColorError {
  color: var(--appColor-errorText);
}
.textColorSuccess {
  color: var(--appColor-successText);
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--appColor-light2);
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--appColor-dark);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--appColor-main);
}

/* For screens larger than 1536px */
@media (min-width: 1535px) {
  .largeScreenSize {
    max-width: 1536px;
  }
  .nav_footerLargeScreenSize {
    width: 1536px;
  }
  .largeScreenLayout {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

/* Textarea style */
textarea {
  resize: none;
  background-color: transparent;
  border: 1px solid var(--appColor-main);
  border-radius: 15px;
}
