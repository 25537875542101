.dividerLine {
  width: 100%;
  height: 1px;
}

.paymentOptionDiv {
  width: 85px;
  height: 51px;
  border-radius: 10px;
  box-shadow: 2px 4px 4px rgba(23, 21, 31, 0.25);
}

.paymentOptionDiv:nth-child(1) .paymentOptionLogo {
  width: 70px;
}
.paymentOptionDiv:nth-child(2) .paymentOptionLogo {
  width: 70px;
}
.paymentOptionDiv:nth-child(3) .paymentOptionLogo {
  width: 43px;
}
.paymentOptionDiv:nth-child(4) .paymentOptionLogo {
  width: 43px;
}
