nav {
  box-shadow: 0px -4px 10px rgba(23, 21, 31, 0.25);
}

/* Mobile navbar button */
#menuBurgerIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
}
#menuBurger {
  width: 20px;
  height: 2px;
  background: var(--appColor-gradient);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
}
#menuBurger::before,
#menuBurger::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background: var(--appColor-gradient);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
}
#menuBurger::before {
  transform: translateY(-6px);
}
#menuBurger::after {
  transform: translateY(6px);
}
/* Animation for menu icon */
#menuBurgerIcon.open #menuBurger {
  transform: translateX(-50px);
  background: transparent;
}
#menuBurgerIcon.open #menuBurger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
#menuBurgerIcon.open #menuBurger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

/* Animation for side navbar */
#mobileNav {
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
  transform: translateX(-100%);
}
#mobileNav.openNav {
  transform: translateX(0%);
}
