:root {
  --select-border: var(--appColor-main);
  --select-arrow: var(--appColor-main);
  --select-focus: var(--appColor-main);
  --select-background: transparent;
  --select-option-background: var(--appColor-light);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

select:focus {
  outline: none;
}
/* Remove arrow for lower IE versions */
select::-ms-expand {
  display: none;
}

/* Remove all default select stylings */
select {
  width: 100%;
  border: none;
  margin: 0;
  padding: 6px 3em 6px 15px;
  appearance: none;
  background-color: transparent;
  cursor: inherit;
}

select,
.dropdownStyle:after {
  grid-area: select;
}

/* On focus */
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid var(--select-focus);
  border-radius: inherit;
}

/* Select options customization */
select option {
  background: var(--select-option-background);
  /* text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
}

/* Select dropdown arrow */
.dropdownStyle::after {
  content: "";
  width: 0.8em;
  height: 0.8em;
  margin: auto 15px auto 0;
  background-color: var(--select-arrow);
  -webkit-clip-path: polygon(
    50% 85%,
    100% 40%,
    100% 55%,
    50% 100%,
    0 55%,
    0 40%
  );
  clip-path: polygon(50% 85%, 100% 40%, 100% 55%, 50% 100%, 0 55%, 0 40%);
  justify-self: end;
}

.dropdownStyle {
  width: 100%;
  position: relative;
  border: 1px solid var(--select-border);
  background-color: var(--select-background);
  cursor: pointer;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
}
.dropdownStyle--rounded {
  border-radius: 50px;
}
.dropdownStyle--slightlyRounded {
  border-radius: 5px;
}
.dropdownStyle--box {
  border-radius: 0;
}
.dropdownStyle--line {
  border: 0;
  box-shadow: 0px 1px 0px var(--select-border);
}
